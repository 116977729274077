<template>
  <section class="block main-about-block">
    <div class="container">
      <div
        v-html="text"
        class="block-inner main-about-inner cms-block-stub"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import type {IBlockDefaultProps} from "#sitis/internal/models/common";
import type {ComputedRef} from "vue";

const props = defineProps<IBlockDefaultProps>();

const text: ComputedRef<string> = computed(() => {
  return props.values?.text || ""
})
</script>

<style lang="scss">

</style>
